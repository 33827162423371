<template>
  <div
    class="container max-w-[440px] mx-auto overflow-y-hidden"
    :class="{
      'safe-inset-t': safe,
    }"
  >
    <slot />
  </div>
</template>
<script lang="ts">
export default {
  props: {
    safe: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
